import { Col, Divider, Row } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { EntityType } from '../../constant';

interface I {
    id: number;
    type: EntityType;
    name: string;
    description: string;
    withDivider?: boolean;
}
const ModelCardComponent: React.FC<I> = props => {
    const history = useHistory();
    return (
        <Row className='model-card' wrap={false} onClick={() => history.push(`/${props.type}/${props.id}`)}>
            <Col className='decoration'>
                <Col className='blue' />
                <Col className='gray' />
            </Col>
            <Col className='content-row'>
                <Row justify='space-between' wrap={false}>
                    <Col style={{ overflow: 'hidden' }}>
                        <Row className='name'>{props.name}</Row>
                        <Row className='description'>{props.description}</Row>
                    </Col>
                    <Col>
                        <Link to={`/${props.type}/${props.id}`}>
                            <button className='small-button'>详情</button>
                        </Link>
                    </Col>
                </Row>
                <Row align='bottom'>{props.withDivider && <Divider className='divider' />}</Row>
            </Col>
        </Row>
    );
};

export default ModelCardComponent;
