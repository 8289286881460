import React from 'react';
import { APP_COVER, EntityType, SWIPER_BREAKPOINT } from 'src/constant';
import HighlightAppSummary from '../components/appHighligthtSummary.component';
import { getImage } from '../utils/placeholder';
import { Link } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import SwiperComp from '../components/swiper';

interface I {
    title: string;
    items: B.AppSummary[];
}
const Highlight: React.FC<I> = props => {
    const { title, items } = props;
    const { trackPageView } = useMatomo();

    // Track page view
    React.useEffect(() => {
        trackPageView({ href: window.location.href });
    }, [trackPageView]);

    return (
        <div className='highlight'>
            <div className='header'>
                <span>{title}</span>
                <span className='tab-button'>
                    <Link to={{ pathname: EntityType.App, state: { chosenTabId: 'highlight' } }}>查看全部</Link>
                </span>
            </div>
            <SwiperComp margin={16} flexInterval={SWIPER_BREAKPOINT}>
                {items.map(app => (
                    <Link key={app.id} to={`/${EntityType.App}/${app.id}`}>
                        <HighlightAppSummary
                            summary={app}
                            width={APP_COVER.width}
                            image={getImage(app.banners?.[0]).jsx}
                        />
                    </Link>
                ))}
            </SwiperComp>
        </div>
    );
};

export default Highlight;
