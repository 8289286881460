import React, { useState, useEffect } from 'react';
import { getModelByCategoryId, getModelCategories } from '../../api/api';
import { ChosenTabStyle } from './solutionSummary.container';
import { Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
import { computeDivider } from '../utils';
import { useHighlight } from '../pages/home.page';
import ModelCardComponent from '../components/modelCard.component';
import { EntityType, LIST_BREAKPOINT } from '../../constant';
import DisplayPadComponent from '../components/displayPad.component';
import ListComponent from '../components/list.component';
import FallbackComponent from '../components/Fallback.component';
import '../style/model-list.less';

const ROW_NUM = 7;
interface S {
    categories: B.ModelCategory[];
}
const TabPaneWithSubCategories: React.FC<S> = props => {
    const { categories } = props;
    const [subIndex, setSubIndex] = useState(0);
    const models = useModelByCategoryId(`${categories[subIndex]?.id ?? ''}`);

    const [columnNum, setColumnNum] = useState(1);

    return (
        <>
            {categories.length > 0 && (
                <div className='select-group-button' style={{ marginBottom: 16 }}>
                    {categories.map((c, i) => (
                        <span key={c.id} className={i === subIndex ? 'selected' : ''} onClick={() => setSubIndex(i)}>
                            {c.name}
                        </span>
                    ))}
                </div>
            )}
            <DisplayPadComponent fallback={<FallbackComponent type='list' />}>
                {models.length > 0 && (
                    <ListComponent
                        breakpoints={LIST_BREAKPOINT}
                        row={ROW_NUM}
                        rowGap='1rem'
                        withPagination
                        paginationTop={-20}
                        onBreak={setColumnNum}
                    >
                        {models.map((m, i) => (
                            <ModelCardComponent
                                id={m.id}
                                key={m.id}
                                type={m.type ?? EntityType.App}
                                name={m.name}
                                description={m.shortIntroduction}
                                withDivider={
                                    computeDivider(columnNum, i, Math.min(models.length, columnNum * ROW_NUM)).lastLine
                                }
                            />
                        ))}
                    </ListComponent>
                )}
            </DisplayPadComponent>
        </>
    );
};

const ModelSummaryContainer: React.FC = () => {
    const modelCategories = useModelCategories();
    const { models: highlights } = useHighlight();

    const location = useLocation<{ chosenTabId: string }>();
    const [chosenTabId, setChosenTabId] = useState('all');
    const models = useModelByCategoryId(chosenTabId);
    useEffect(() => {
        location.state?.chosenTabId && setChosenTabId(location.state?.chosenTabId);
    }, [location.state?.chosenTabId]);

    const [columnNum, setColumnNum] = useState(1);

    const generateTabPane = (models: B.Model[], tab: string, key: string) => (
        <Tabs.TabPane tab={tab} key={key}>
            <DisplayPadComponent paddingBottom={10}>
                <ListComponent
                    breakpoints={LIST_BREAKPOINT}
                    row={ROW_NUM}
                    rowGap='1rem'
                    withPagination
                    paginationTop={-20}
                    onBreak={setColumnNum}
                >
                    {models.map((m, i) => (
                        <ModelCardComponent
                            id={m.id}
                            key={m.id}
                            type={m.type ?? EntityType.App}
                            name={m.name}
                            description={m.shortIntroduction}
                            withDivider={
                                computeDivider(columnNum, i, Math.min(models.length, columnNum * ROW_NUM)).lastLine
                            }
                        />
                    ))}
                </ListComponent>
            </DisplayPadComponent>
        </Tabs.TabPane>
    );

    return (
        <ChosenTabStyle className='entity-list'>
            <Tabs tabBarStyle={{ marginBottom: 16 }} activeKey={chosenTabId ?? 'all'} onChange={setChosenTabId}>
                {generateTabPane(models, '全部模型', 'all')}
                {generateTabPane(highlights, '精选', 'highlight')}
                {modelCategories.map(c => (
                    <Tabs.TabPane key={`${c.id}`} tab={c.name} tabKey={`${c.id}`}>
                        <TabPaneWithSubCategories categories={c.children ?? []} />
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </ChosenTabStyle>
    );
};

export default ModelSummaryContainer;

export const useModelCategories = () => {
    const [modelCategories, setModelCategories] = useState<B.ModelCategory[]>([]);
    useEffect(() => {
        getModelCategories().then(setModelCategories);
    }, []);
    return modelCategories;
};

const useModelByCategoryId = (categoryId?: string) => {
    const [models, setModels] = useState<B.Model[]>([]);
    useEffect(() => {
        if (categoryId === 'all') getModelByCategoryId().then(setModels);
        else {
            if (categoryId && !Number.isNaN(+categoryId)) getModelByCategoryId(+categoryId).then(setModels);
        }
    }, [categoryId]);
    return models;
};
