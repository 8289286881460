import React from 'react';

const AppDetailPart: React.FC<{ title: string; categories: string[]; version: string }> = ({
    title,
    categories,
    version,
}) => {
    return (
        <div className='meta'>
            <h2>{title}</h2>
            <div>
                <span className='field-name'>类别: </span>
                <span className='field-value'>{categories.join(' ')}</span>
            </div>
            <div>
                <span className='field-name'>版本: </span>
                <span className='field-value'>{version}</span>
            </div>
        </div>
    );
};

export default AppDetailPart;
