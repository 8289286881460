import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

type TrackEventCategory = '搜索' | '推荐' | '留资' | '页面' | '跳转';

export function trackEvent(category: TrackEventCategory, action: string, label: string) {
    (window as any)._hmt.push(['_trackEvent', 'apps.' + category, action, label]);
}

export function useTrackVisit(label: string, deps: any[] = []) {
    const location = useLocation();
    useEffect(() => {
        if (label) trackEvent('页面', `${label} [${location.pathname}]`, location.pathname + location.search);
    }, [label, location.pathname, deps, location.search]);
}

export function useUrlTracker() {
    const currentLocation = useLocation();
    const history = useHistory();
    useEffect(() => {
        return history.listen(location => {
            trackEvent('跳转', currentLocation.pathname, location.pathname);
        });
    }, [history, currentLocation]);
}
