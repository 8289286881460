import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navigator from '../components/navigator.component';
import { Result, Button } from 'antd';
import Header from './header.page';
import _ from 'lodash';
import { trackEvent } from '../../api/seo';

const ContactSuccessPage: React.FC = () => {
    useEffect(() => {
        trackEvent('留资', '留资成功', '');
    }, []);

    return (
        <div className='contact-page'>
            <Header leftComponent={<Navigator info={[{ name: '联系我们' }]} />} />
            <Result
                status='success'
                title='您的信息已经成功提交'
                extra={[
                    <Link to='/' key='console'>
                        <Button type='primary'>回到首页</Button>
                    </Link>,
                ]}
            />
        </div>
    );
};

export default ContactSuccessPage;
