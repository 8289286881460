import React from 'react';
import { Carousel } from 'antd';
import { Link } from 'react-router-dom';

const CarouselWithImage: React.FC<{
    data: {
        imageUrl: string;
        description: JSX.Element;
        url: string;
    }[];
}> = ({ data }) => {
    return (
        <>
            <Carousel className='carousel' autoplay>
                {data.map(({ url, imageUrl, description }) => (
                    <div className='slider' key={imageUrl}>
                        <Link to={url ?? '/'}>
                            <img src={imageUrl} alt='slide' />
                        </Link>
                        {description}
                    </div>
                ))}
            </Carousel>
        </>
    );
};

export default CarouselWithImage;
