import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AppDetailPart from '../components/appDetailPart.component';
import { Divider, Image, Space, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import Btn from '../components/myButton.component';
import { getImage } from '../utils/placeholder';
import { getRecommendation } from '../../api/api';
import { EntityType, LIST_BREAKPOINT, LOGIN_URL, SWIPER_BREAKPOINT } from '../../constant';
import ModelCardComponent from '../components/modelCard.component';
import DisplayPadComponent from '../components/displayPad.component';
import SwiperComp from '../components/swiper';
import ListComponent from '../components/list.component';
import { computeDivider } from '../utils';
import AppSummaryCard from '../components/appSummaryCard.component';
import ReactMarkdown from 'react-markdown';
import DetailIntroductionV2Component from '../components/DetailIntroductionV2.component';
import DetailEqualWidthAndSwipeComponent from '../components/DetailEqualWidthAndSwipe.component';
import DetailEqualWidthAndTileComponent from '../components/DetailEqualWidthAndTile.component';
import DetailAlwaysTwoColumnAndSwipeComponent from '../components/DetailAlwaysTwoColumnAndSwipe.component';
import { trackEvent } from '../../api/seo';
import { useModelTrailUrl } from 'src/service/cloudbase';

const ModelDetail: React.FC<{
    id: number;
    type: EntityType;
    imageUrls: string[];
    logo: JSX.Element;
    title: string;
    categories: string[];
    version: string;
    shortIntroduction: string;
    longIntroduction: B.LongIntroPart[][];
    longIntroductionV2?: B.LongIntroUnit[];
    video?: string;
}> = props => {
    const { logo, imageUrls, title, categories, version, shortIntroduction, longIntroduction } = props;
    const { modelId, appId } = useParams<{ modelId: string; appId: string }>();
    const recommends = useRecommendation(props.type, props.id);
    const [recomendationColumnNum, setRecomendationNum] = useState(1);

    const contactUrl = `${LOGIN_URL}?text=appstore-${props.type}${modelId ?? appId}-${encodeURIComponent(
        title,
    )}&redirect=${encodeURIComponent(window.location.origin + '/contact/success')}`;

    // 这部分是指向App Store内部的留资页组件，目前使用官网的注册、留资系统
    // const history = useHistory();
    // const ContactBtnInsideAppstore = (
    //     <Link
    //         to={{
    //             pathname: '/contact',
    //             state: [
    //                 props.type === EntityType.Model
    //                     ? { name: '全部模型', link: `/${EntityType.Model}` }
    //                     : { name: '全部APP', link: `/${EntityType.App}` },
    //                 { name: title, link: history.location.pathname },
    //             ],
    //         }}
    //     >
    //         <Btn.Primary>获取试用</Btn.Primary>
    //     </Link>
    // );

    const onClickContactBtn = () => {
        trackEvent('留资', '获取试用', title);
        (window as any)._agl && (window as any)._agl.push(['track', ['success', { t: 18 }]]);
        window.location.href = contactUrl;
    };

    const onClickRecommendation: React.MouseEventHandler = e => {
        trackEvent('推荐', `<${title}>的相关推荐`, e.currentTarget.baseURI);
    };

    const onClickImage = () => {
        trackEvent('页面', '点击', `<${title}>的图片`);
    };

    const trialUrl = useModelTrailUrl(props.id);

    console.log({ trialUrl });

    return (
        <div className='app-detail'>
            <Head>
                <div
                    className='logo middle-logo'
                    style={EntityType.Model === props.type ? { borderRadius: '50%' } : undefined}
                >
                    {logo}
                </div>
                <div style={{ flex: 1 }}>
                    <AppDetailPart title={title} categories={categories} version={version} />
                </div>
                {trialUrl.data && trialUrl.data?.length > 0 ? (
                    <Space>
                        <a href={trialUrl.data[0].trial_url}>
                            <Btn.Primary>在线使用</Btn.Primary>
                        </a>
                        <Btn.Primary onClick={onClickContactBtn}>获取试用</Btn.Primary>
                    </Space>
                ) : (
                    <Btn.Primary onClick={onClickContactBtn}>获取试用</Btn.Primary>
                )}
            </Head>
            <Divider />

            {imageUrls.length > 1 ? (
                <SwiperComp margin={30} flexInterval={SWIPER_BREAKPOINT} noBlankTail>
                    {imageUrls.map(url => (
                        <Image className='cover' key={url} src={url} alt={url} onClick={onClickImage} />
                    ))}
                </SwiperComp>
            ) : (
                <div className='single-cover'>{imageUrls[0] && <img src={imageUrls[0]} alt='cover' />}</div>
            )}

            <ReactMarkdown className='introduction'>{shortIntroduction}</ReactMarkdown>

            {props.longIntroductionV2 && props.longIntroductionV2.length > 0 ? (
                props.longIntroductionV2.map(l => <DetailIntroductionV2Component key={l.title} longIntroUnit={l} />)
            ) : (
                <>
                    {longIntroduction[0].length > 0 && (
                        <DetailEqualWidthAndSwipeComponent
                            title={`${props.type === EntityType.Model ? '模型' : 'APP'}亮点`}
                            content={longIntroduction[0]}
                        />
                    )}
                    {longIntroduction[1].length > 0 && (
                        <DetailEqualWidthAndTileComponent title='应用场景' content={longIntroduction[1]} />
                    )}

                    {longIntroduction[2].length > 0 && (
                        <DetailAlwaysTwoColumnAndSwipeComponent title='客户案例' content={longIntroduction[2]} />
                    )}
                </>
            )}

            {recommends.length > 0 && (
                <>
                    <Typography.Title className='title' level={4}>
                        相关{props.type === EntityType.Model ? '模型' : 'APP'}推荐
                    </Typography.Title>

                    <div onClick={onClickRecommendation}>
                        <DisplayPadComponent paddingTop={32}>
                            <ListComponent
                                breakpoints={LIST_BREAKPOINT}
                                row={10}
                                rowGap='10px'
                                columnGap='40px'
                                onBreak={setRecomendationNum}
                            >
                                {recommends.map((l, i) => {
                                    switch (props.type) {
                                        case EntityType.Model:
                                            return (
                                                <ModelCardComponent
                                                    id={l.id}
                                                    key={l.id}
                                                    type={l.type}
                                                    name={l.name}
                                                    description={l.shortIntroduction}
                                                    withDivider={
                                                        computeDivider(
                                                            recomendationColumnNum,
                                                            i,
                                                            Math.min(recommends.length, 2 * recomendationColumnNum),
                                                        ).lastLine
                                                    }
                                                />
                                            );
                                        case EntityType.App:
                                            return (
                                                <AppSummaryCard
                                                    key={l.id}
                                                    id={l.id}
                                                    image={getImage((l as B.AppSummary).logo).jsx}
                                                    title={l.name}
                                                    description={l.shortIntroduction}
                                                    withBottomDivider={
                                                        computeDivider(
                                                            recomendationColumnNum,
                                                            i,
                                                            Math.min(recommends.length, 10 * recomendationColumnNum),
                                                        ).lastLine
                                                    }
                                                />
                                            );
                                        default:
                                            return <></>;
                                    }
                                })}
                            </ListComponent>
                        </DisplayPadComponent>
                    </div>
                </>
            )}
        </div>
    );
};

export default ModelDetail;

const useRecommendation = (type: EntityType, id: number) => {
    const [entities, setEntities] = useState<(B.AppSummary | B.Model)[]>([]);
    useEffect(() => {
        getRecommendation(type, id).then(setEntities);
    }, [id, type]);
    return entities;
};

const Head = styled.div`
    display: flex;
    align-items: center;
`;
