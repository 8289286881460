import React, { useState, useEffect } from 'react';
import fallbackEmptyBox from '../../resource/image/fallback-empty-box.svg';
import fallbackEmptySearch from '../../resource/image/fallback-empty-search.svg';

interface I {
    type: 'search' | 'list';
}
const FallbackComponent: React.FC<I> = props => {
    let img = '';
    let hint = '';

    if (props.type === 'search') {
        img = fallbackEmptySearch;
        hint = '很抱歉，未检索到相关产品，换个词试试';
    }

    if (props.type === 'list') {
        img = fallbackEmptyBox;
        hint = '很抱歉，未检索到相关商品';
    }

    return (
        <div className='fallback'>
            <img src={img} alt='nothing-found' />
            <div className='hint'>{hint}</div>
        </div>
    );
};

export default FallbackComponent;
