import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getModelById } from 'src/api/api';
import Navigator from '../components/navigator.component';
import { getImage } from 'src/react/utils/placeholder';
import '../style/appDetail.less';
import ModelDetail from '../containers/modelDetail.container';
import { EntityType } from '../../constant';
import Header from './header.page';
import { useTrackVisit } from '../../api/seo';

const ModelPage = () => {
    const { modelId } = useParams<{ modelId: string }>();

    const [model, setApp] = useState<B.Model>();
    useEffect(() => {
        getModelById(+modelId).then(setApp);
    }, [modelId]);

    useTrackVisit(model?.name ?? '', [model]);
    return (
        <>
            <Header
                leftComponent={
                    <Navigator info={[{ name: '全部模型', link: `/${EntityType.Model}` }, { name: '模型服务详情' }]} />
                }
                hiddenSearch
            />
            <div className='page-padding'>
                {model && (
                    <ModelDetail
                        id={model.id}
                        type={EntityType.Model}
                        logo={getImage(model.categories[0].image ?? model.categories[0].parent?.image).jsx}
                        imageUrls={model.covers.map(name => getImage(name).url)}
                        title={model.name}
                        categories={model.categories.map(c => c.name)}
                        version={model.version}
                        shortIntroduction={model.detailShortIntroduction}
                        longIntroduction={[model.spotIntroduction, model.sceneIntroduction, model.caseIntroduction]}
                        longIntroductionV2={model.detailIntroductionV2}
                    />
                )}
            </div>
        </>
    );
};

export default ModelPage;
