import axios from 'axios';
import { message } from 'antd';

const instance = axios.create();

instance.interceptors.request.use(config => {
    if (config.method === 'get') {
        if (!config.params) {
            config.params = {};
        }
    }
    return config;
});
instance.interceptors.response.use(
    response => {
        const msg = response.data.message;
        if (msg) {
            message.info(msg);
        }

        return response;
    },
    error => {
        console.log(error.response);
        const errorMsg = error.response.data?.msg || error.response.data?.message || error.toString();
        message.error(errorMsg);
        process.env.NODE_ENV === 'development' && console.error('Axios', errorMsg);
        return Promise.reject(error);
    },
);

export default instance;
