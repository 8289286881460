import React, { useState, useEffect } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getSearchAppModel } from '../../api/api';
import { EntityType, LIST_BREAKPOINT } from '../../constant';
import AppSummaryCard from '../components/appSummaryCard.component';
import CatelogyChooseComponent from '../components/CatelogyChoose.component';
import ModelCardComponent from '../components/modelCard.component';
import { getImage } from '../utils/placeholder';
import { useSolutions } from './appSummary.container';
import { useModelCategories } from './modelSummary.container';
import _ from 'lodash';
import DisplayPadComponent from '../components/displayPad.component';
import ListComponent from '../components/list.component';
import { computeDivider } from '../utils';
import FallbackComponent from '../components/Fallback.component';
import { Divider } from 'antd';
import { trackEvent } from '../../api/seo';
import useSWR from 'swr';

const ROW_NUM = 5;
interface S {
    entities: (B.AppSummary | B.Model)[];
}
const AppList: React.FC<S> = props => {
    const [columnNum, setColumnNum] = useState(1);
    const [currentChildOffset, setCurrentChildOffset] = useState(0);
    const [currentChildNum, setCurrentChildNum] = useState(0);
    return (
        <>
            <ListComponent
                breakpoints={LIST_BREAKPOINT}
                row={4}
                rowGap='1rem'
                withPagination
                paginationTop={-20}
                onBreak={setColumnNum}
                setCurrentChildOffset={setCurrentChildOffset}
                setCurrentChildNum={setCurrentChildNum}
            >
                {props.entities.map((app, index) =>
                    app.type === EntityType.Model ? (
                        <ModelCardComponent
                            id={app.id}
                            key={app.id}
                            type={app.type ?? EntityType.App}
                            name={app.name}
                            description={app.shortIntroduction}
                            withDivider={
                                computeDivider(columnNum, index - currentChildOffset, currentChildNum).lastLine
                            }
                        />
                    ) : (
                        <AppSummaryCard
                            id={app.id}
                            key={app.id}
                            title={app.name}
                            description={app.shortIntroduction}
                            image={getImage((app as B.AppSummary).logo).jsx}
                            withBottomDivider={
                                computeDivider(columnNum, index - currentChildOffset, currentChildNum).lastLine
                            }
                        />
                    ),
                )}
            </ListComponent>
        </>
    );
};

export type Cascade = [string, string, string];

const SearchResultContainer: React.FC = () => {
    const { text } = useParams<{ text: string }>();
    const { data: searchResult, isLoading } = useSearch(text);

    const apps = searchResult.filter(r => r.type !== EntityType.Model) as B.AppSummary[];
    const models = searchResult.filter(r => r.type === EntityType.Model) as B.Model[];
    const categories = useModelCategories();
    const solutions = useSolutions();

    const [selectedIds, setSelectedIds] = useState<Cascade>(['all', 'all', 'all']);

    const onSelect = (levelIdx: number) => (id: string) => {
        const newCascade = selectedIds.map((e, i) => {
            // 父层级不变
            if (i < levelIdx) return e;
            // 本层级变
            if (i === levelIdx) return id;
            // 子层级变为初始值 all
            return 'all';
        }) as Cascade;

        trackEvent(
            '搜索',
            '选择分类',
            newCascade
                .map(
                    // categories是一个最多三层的tree
                    (id, idx) =>
                        categories.find(c => +c.id === +id)?.name ??
                        categories.find(c => +c.id === +newCascade[idx - 1])?.children.find(c => +c.id === +id)?.name ??
                        id,
                )
                .join('-'),
        );
        setSelectedIds(newCascade);
    };
    const filter = (entities: B.Model[] | B.AppSummary[], categoryId: string) => {
        if (entities.length === 0) return [];
        if (categoryId === 'all') return entities;
        if (entities[0].type === EntityType.App)
            return (entities as B.AppSummary[]).filter(e => e.solutions.includes(categoryId));
    };

    const categoryMap = useMemo(() => {
        const modelMap: Record<string, string[]> = {};
        categories.forEach(c => {
            modelMap[`${c.id}`] = c.children.map(k => `${k.id}`);
            if (c.children.length > 0) {
                c.children.forEach(e => {
                    modelMap[`${e.id}`] = [`${e.id}`];
                });
            }
        });
        return modelMap;
    }, [categories]);

    return (
        <div className='search-result'>
            <DisplayPadComponent
                fallback={<FallbackComponent type='search' />}
                showFallback={searchResult.length === 0}
                paddingTop={22}
                paddingBottom={10}
                isLoading={isLoading}
            >
                <CatelogyChooseComponent
                    appSolutions={solutions}
                    modelCategories={categories}
                    selectedIds={selectedIds}
                    onSelect={onSelect}
                />
                {searchResult.length > 0 && (
                    <div className='conclusion'>
                        {selectedIds[0] === 'all' && (
                            <span>
                                共搜索到{searchResult.length}个相关结果，APP应用{apps.length}个，模型服务{models.length}
                                个
                            </span>
                        )}

                        {selectedIds[0] === EntityType.App && <span>共搜索到{apps.length}个相关结果</span>}

                        {selectedIds[0] === EntityType.Model && <span>共搜索到{models.length}个相关结果</span>}
                    </div>
                )}

                {selectedIds[0] === 'all' && apps.length > 0 && <h2>APP应用</h2>}
                {['all', EntityType.App].includes(selectedIds[0]) && apps.length > 0 && (
                    <AppList entities={filter(apps, selectedIds[1]) ?? []} />
                )}

                {selectedIds[0] === 'all' && models.length > 0 && apps.length > 0 && (
                    <Divider className='big-divider' />
                )}
                {selectedIds[0] === 'all' && models.length > 0 && <h2>模型服务</h2>}
                {['all', EntityType.Model].includes(selectedIds[0]) && models.length > 0 && (
                    <AppList entities={modelFilter(models)(selectedIds, categoryMap)} />
                )}
            </DisplayPadComponent>
        </div>
    );
};

export default SearchResultContainer;

const useSearch = (text: string) => {
    const fetcher = async (text: string) => {
        const data = await getSearchAppModel(text);
        if (data.length === 0) trackEvent('搜索', '无结果', text);
        else trackEvent('搜索', `搜索到${data.length}个结果`, text);
        return data;
    };
    const key = text ? text : null;
    const { data, error } = useSWR(key, fetcher);
    const isLoading = !error && !data;
    return { data: data ?? [], isLoading };
};

const lastElementNotEqualAll = (arr: string[]): string | undefined => {
    const lastAllIndex = arr.indexOf('all');
    if (lastAllIndex === -1) return _.last(arr);
    return arr[lastAllIndex - 1];
};

const modelFilter = (models: B.Model[]) => (selectedId: Cascade, categoryMap: Record<string, string[]>) => {
    const key = lastElementNotEqualAll(selectedId);
    if (!key) return models;
    if (key === EntityType.Model) return models;
    return models.filter(
        m =>
            _.intersection(
                m.categories.map(c => `${c.id}`),
                categoryMap[key],
            ).length > 0,
    );
};
