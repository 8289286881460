import React from 'react';
import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';

const HighlightAppSummary: React.FC<{ summary: B.AppSummary; image: JSX.Element; width: number }> = ({
    summary,
    image,
}) => {
    return (
        <CardItem className='highlight-card app'>
            <div className='image'>{image}</div>
            <div className='content'>
                <h3 className='title'>{summary.name}</h3>
                <div className='description'>{summary.shortIntroduction}</div>
                <div className='arrow-icon'>
                    <span>详情</span>
                    <RightOutlined />
                </div>
            </div>
        </CardItem>
    );
};

export default HighlightAppSummary;

const CardItem = styled.div`
    border-radius: 8px;
    box-shadow: 3px 4px 10px 0 rgba(120, 125, 135, 0.08);
    background-image: linear-gradient(to bottom, #f7fbfe, #f7fbfe),
        linear-gradient(128deg, #ffffff 23%, rgba(255, 255, 255, 0.85) 74%);
    padding: 24px;

    display: flex;
    flex-direction: row;
`;
