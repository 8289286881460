import React from 'react';
import AppSummaryList from '../containers/appSummary.container';
import Navigator from '../components/navigator.component';
import Header from './header.page';

const AllApps = () => {
    return (
        <>
            <Header leftComponent={<Navigator info={[{ name: '全部' }]} />} />
            <div className='page-padding'>
                <AppSummaryList />
            </div>
        </>
    );
};

export default AllApps;
