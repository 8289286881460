import React, { useEffect, useState } from 'react';
import { getImage } from '../utils/placeholder';
import api, { LocalSolution } from 'src/api/api';
import { Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
import AppSummaryCard from '../components/appSummaryCard.component';
import { ChosenTabStyle } from './solutionSummary.container';
import { computeDivider } from '../utils';
import { EntityType, LIST_BREAKPOINT } from '../../constant';
import DisplayPadComponent from '../components/displayPad.component';
import ListComponent from '../components/list.component';
import FallbackComponent from '../components/Fallback.component';
import { useTrackVisit } from '../../api/seo';
import useSWR from 'swr';

const ROW_NUM = 7;

interface SolutionPaneProps {
    solutionId: number;
    columnNum: number;
    setColumnNum: (c: number) => void;
}
const SolutionPane: React.FC<SolutionPaneProps> = props => {
    const { solutionId, columnNum, setColumnNum } = props;
    const apps = useAppBySolutionId(solutionId);
    const [currentChildOffset, setCurrentChildOffset] = useState(0);
    return (
        <DisplayPadComponent fallback={<FallbackComponent type='list' />}>
            {apps.length > 0 && (
                <ListComponent
                    breakpoints={LIST_BREAKPOINT}
                    row={ROW_NUM}
                    rowGap='1rem'
                    withPagination
                    onBreak={setColumnNum}
                    setCurrentChildOffset={setCurrentChildOffset}
                >
                    {apps.map((app, index) => (
                        <AppSummaryCard
                            id={app.id}
                            key={app.id}
                            title={app.name}
                            description={app.shortIntroduction}
                            image={getImage(app.logo).jsx}
                            withBottomDivider={
                                computeDivider(
                                    columnNum,
                                    index - currentChildOffset,
                                    Math.min(apps.length - currentChildOffset, columnNum * ROW_NUM),
                                ).lastLine
                            }
                        />
                    ))}
                </ListComponent>
            )}
        </DisplayPadComponent>
    );
};

interface AllPaneProps {
    columnNum: number;
    setColumnNum: (c: number) => void;
}
const AllPane: React.FC<AllPaneProps> = props => {
    const { columnNum, setColumnNum } = props;
    const apps = useApps(0, 100);
    const [currentChildOffset, setCurrentChildOffset] = useState(0);
    return (
        <DisplayPadComponent fallback={<FallbackComponent type='list' />}>
            {apps.length > 0 && (
                <ListComponent
                    breakpoints={LIST_BREAKPOINT}
                    row={ROW_NUM}
                    rowGap='1rem'
                    withPagination
                    onBreak={setColumnNum}
                    setCurrentChildOffset={setCurrentChildOffset}
                >
                    {apps.map((app, index) => (
                        <AppSummaryCard
                            id={app.id}
                            key={app.id}
                            title={app.name}
                            description={app.shortIntroduction}
                            image={getImage(app.logo).jsx}
                            withBottomDivider={
                                computeDivider(
                                    columnNum,
                                    index - currentChildOffset,
                                    Math.min(apps.length - currentChildOffset, columnNum * ROW_NUM),
                                ).lastLine
                            }
                        />
                    ))}
                </ListComponent>
            )}
        </DisplayPadComponent>
    );
};

const HighlightPane: React.FC<AllPaneProps> = props => {
    const { columnNum, setColumnNum } = props;
    const apps = useHighlights();
    return (
        <DisplayPadComponent fallback={<FallbackComponent type='list' />}>
            {apps.length > 0 && (
                <ListComponent
                    breakpoints={LIST_BREAKPOINT}
                    row={ROW_NUM}
                    rowGap='1rem'
                    withPagination
                    onBreak={setColumnNum}
                >
                    {apps.map((app, index) => (
                        <AppSummaryCard
                            id={app.id}
                            key={app.id}
                            title={app.name}
                            description={app.shortIntroduction}
                            image={getImage(app.logo).jsx}
                            withBottomDivider={
                                computeDivider(columnNum, index, Math.min(apps.length, columnNum * ROW_NUM)).lastLine
                            }
                        />
                    ))}
                </ListComponent>
            )}
        </DisplayPadComponent>
    );
};

const generateTabPane = (tab: string, key: string, columnNum: number, setColumnNum: (c: number) => void) => {
    return (
        <Tabs.TabPane tab={tab} key={key}>
            <SolutionPane solutionId={Number.parseInt(key)} columnNum={columnNum} setColumnNum={setColumnNum} />
        </Tabs.TabPane>
    );
};

const AppSummaryList = () => {
    const solutions = useSolutions();
    const location = useLocation<{ chosenTabId: string }>();
    const [chosenTabId, setChosenTabId] = useState(location.state?.chosenTabId ?? 'highlight');

    useTrackVisit(chosenTabId ? solutions.find(s => +s.id === +chosenTabId)?.name ?? chosenTabId : '', [
        chosenTabId,
        solutions,
    ]);

    const [columnNum, setColumnNum] = useState(1);

    return (
        <ChosenTabStyle className='apps'>
            <Tabs
                tabBarStyle={{ marginBottom: 16 }}
                activeKey={chosenTabId ?? 'all'}
                onChange={(key: string) => {
                    setChosenTabId(key);
                }}
            >
                <Tabs.TabPane tabKey='all' tab='全部应用' key='all'>
                    <AllPane columnNum={columnNum} setColumnNum={setColumnNum} />
                </Tabs.TabPane>
                <Tabs.TabPane tabKey='highlight' tab='精选' key='highlight'>
                    <HighlightPane columnNum={columnNum} setColumnNum={setColumnNum} />
                </Tabs.TabPane>
                {solutions.map(solution => generateTabPane(solution.name, `${solution.id}`, columnNum, setColumnNum))}
            </Tabs>
        </ChosenTabStyle>
    );
};

export default AppSummaryList;

export const useSolutions = () => {
    const { data, error } = useSWR('/solutions', api.getSolutions);
    return data ?? [];
};

const useHighlights = () => {
    const [highlights, setHighlights] = useState<B.AppSummary[]>([]);
    useEffect(() => {
        api.getHighlightApps().then(h => setHighlights(h.apps));
    }, []);
    return highlights;
};

const useApps = (start: number, max: number) => {
    const [apps, setApps] = useState<Res.Apps[]>([]);
    useEffect(() => {
        api.getAllAppSummaries(start, max).then(setApps);
    }, [max, start]);
    return apps;
};

const useAppBySolutionId = (solutionId: number) => {
    const fetchKey = Number.isNaN(solutionId) ? null : [EntityType.App, String(solutionId)];
    const { data, error } = useSWR(fetchKey, (url: string, solutionId: string) => api.getAppBySolutionId(solutionId));
    return data ?? [];
};
