import React, { useState, useEffect } from 'react';
import SearchComponent from './search.component';
import searchIcon from '../style/image/serch-icon.svg';
import { MOBILE_WIDTH } from '../../constant';

const SearchMobileComponent: React.FC = props => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH);
    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth < MOBILE_WIDTH) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        });
    }, []);

    const [display, setDisplay] = useState(false);

    return (
        <div>
            {isMobile && !display && (
                <img style={{ width: 42 }} src={searchIcon} alt='搜索文本' onClick={() => setDisplay(true)} />
            )}
            {!isMobile && <SearchComponent />}
            {display && (
                <div className='search-mobile-layout'>
                    <SearchComponent className='mobile' onHidden={() => setDisplay(false)} />
                </div>
            )}
        </div>
    );
};

export default SearchMobileComponent;
