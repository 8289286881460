import { Divider } from 'antd';
import React from 'react';
import { EntityType } from '../../constant';
import { Cascade } from '../containers/searchResult.container';

interface Element {
    id: number | string;
    name: string;
    children?: Element[];
}

interface I {
    appSolutions: Element[];
    modelCategories: Element[];
    selectedIds: Cascade;
    onSelect: (index: number) => (value: string) => void;
}
const CatelogyChooseComponent: React.FC<I> = props => {
    const { selectedIds, onSelect } = props;

    const second = selectedIds[0] === EntityType.Model && props.modelCategories.find(c => `${c.id}` === selectedIds[1]);

    const selectedClassname = (level: 0 | 1 | 2) => (key: string) => (selectedIds[level] === key ? 'selected' : '');

    return (
        <div className='category-choose'>
            <span className={selectedClassname(0)('all')} onClick={() => onSelect(0)('all')}>
                全部
            </span>
            <span className={selectedClassname(0)(EntityType.App)} onClick={() => onSelect(0)(EntityType.App)}>
                APP
            </span>
            <span className={selectedClassname(0)(EntityType.Model)} onClick={() => onSelect(0)(EntityType.Model)}>
                模型
            </span>

            {selectedIds[0] === EntityType.App && (
                <>
                    <Divider className='divider' />
                    <span className={selectedClassname(1)('all')} onClick={() => onSelect(1)('all')}>
                        全部
                    </span>
                    {props.appSolutions.map(s => (
                        <span
                            key={s.id}
                            className={selectedClassname(1)(`${s.id}`)}
                            onClick={() => onSelect(1)(`${s.id}`)}
                        >
                            {s.name}
                        </span>
                    ))}
                </>
            )}
            {selectedIds[0] === EntityType.Model && (
                <>
                    <Divider className='divider' />
                    <span className={selectedClassname(1)('all')} onClick={() => onSelect(1)('all')}>
                        全部
                    </span>
                    {props.modelCategories.map(c => (
                        <span
                            key={c.id}
                            className={selectedClassname(1)(`${c.id}`)}
                            onClick={() => onSelect(1)(`${c.id}`)}
                        >
                            {c.name}
                        </span>
                    ))}
                    {second && <Divider className='divider' />}
                    {second && (
                        <span className={selectedClassname(2)('all')} onClick={() => onSelect(2)('all')}>
                            全部
                        </span>
                    )}
                    {second &&
                        second?.children?.map(c => (
                            <span
                                key={c.id}
                                className={selectedClassname(2)(`${c.id}`)}
                                onClick={() => onSelect(2)(`${c.id}`)}
                            >
                                {c.name}
                            </span>
                        ))}
                </>
            )}
            <Divider className='divider' />
        </div>
    );
};

export default CatelogyChooseComponent;
