import React, { useState } from 'react';
import { Typography, Row } from 'antd';
import ReactMarkdown from 'react-markdown';
import { LIST_BREAKPOINT } from '../../constant';
import { computeDivider } from '../utils';
import { getImage } from '../utils/placeholder';
import DisplayPadComponent from './displayPad.component';
import ListComponent from './list.component';

interface I {
    title: string;
    content: B.LongIntroPart[];
}
const DetailEqualWidthAndTileComponent: React.FC<I> = props => {
    const { title, content } = props;
    const [sceneColumnNum, setSceneColumnNum] = useState(1);

    return (
        <>
            <Typography.Title className='title' level={4}>
                {title}
            </Typography.Title>
            <DisplayPadComponent paddingTop={32} paddingBottom={32} className='scene'>
                <ListComponent
                    breakpoints={LIST_BREAKPOINT}
                    row={7} // 随便写了一个大数，来复用这个组件。之后的内容会被截断
                    rowGap='0px'
                    columnGap='0px'
                    onBreak={setSceneColumnNum}
                >
                    {content.map((l, i) => {
                        const isNotLastLine = computeDivider(sceneColumnNum, i, content.length).lastLine;
                        return (
                            <div key={l.title} className={isNotLastLine ? 'item with-border' : 'item'}>
                                <div
                                    className={
                                        computeDivider(sceneColumnNum, i, content.length).vertical
                                            ? 'item-content-wrapper'
                                            : ''
                                    }
                                >
                                    <div className='item-content'>
                                        {l.imageName && (
                                            <Row className='symbol'>
                                                <img
                                                    width='auto'
                                                    height={64}
                                                    src={getImage(l.imageName).url}
                                                    alt='logo'
                                                />
                                            </Row>
                                        )}
                                        <Row>
                                            <Typography.Title className='sub-title' level={5}>
                                                {l.title}
                                            </Typography.Title>
                                        </Row>
                                        <Row>
                                            <ReactMarkdown className='content'>{l.content}</ReactMarkdown>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </ListComponent>
            </DisplayPadComponent>
        </>
    );
};

export default DetailEqualWidthAndTileComponent;
