import React, { memo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import AllApps from './react/pages/apps.page';
import Home from './react/pages/home.page';
import AppPage from './react/pages/appDetail.page';
import ModelsPage from './react/pages/models.page';
import ModelPage from './react/pages/modelDetail.page';
import { EntityType } from './constant';
import SearchResultPage from './react/pages/SearchResult.page';
import ContactSuccessPage from './react/pages/ContactSuccess.page';
import ScrollToTop from './react/components/scollToTop.component';
import { useUrlTracker } from './api/seo';

const App: React.FC<Record<string, unknown>> = memo(() => {
    useUrlTracker();

    return (
        <Page>
            <ScrollToTop />
            <Switch>
                <Route path='/' exact>
                    <Home />
                </Route>
                <Route path={`/${EntityType.App}`} exact>
                    <AllApps />
                </Route>
                <Route path={`/${EntityType.Model}`} exact>
                    <ModelsPage />
                </Route>
                <Route path='/search/:text'>
                    <SearchResultPage />
                </Route>
                <Route path={`/${EntityType.App}/:appId`}>
                    <AppPage />
                </Route>
                <Route path={`/${EntityType.Model}/:modelId`}>
                    <ModelPage />
                </Route>
                {/* <Route path='/contact' exact>
                        <Contact />
                    </Route> */}
                <Route path='/contact/success'>
                    <ContactSuccessPage />
                </Route>
                <Redirect to='/' />
            </Switch>
        </Page>
    );
});

export default App;

const Page = styled.div`
    width: 100%;
`;
