export const withDividerByIndex = (index: number, columns: number, total: number) => {
    return index < total - columns;
};

export const computeDivider = (column: number, index: number, total: number) => {
    let vertical = true;
    if (index % column === 0) vertical = false;
    else vertical = true;

    let horizontal = true;
    if (index > total - column - 1) horizontal = false;

    let lastLine = true;
    // 正好除尽，最后一行为false
    if (total % column === 0 && index > total - column - 1) lastLine = false;
    // 未除尽
    if (index > total - (total % column) - 1) lastLine = false;

    const output = { vertical, horizontal, lastLine };
    // console.log({ column, index, total }, output);
    return output;
};

export function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
