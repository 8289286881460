import { Pagination } from 'antd';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

interface I {
    breakpoints: { uptoWidth: number; colum: number }[];
    row: number;
    rowGap: string;
    columnGap?: string;
    withPagination?: boolean;
    paginationTop?: number;
    onBreak?: (columNum: number) => void;
    setCurrentChildNum?: (length: number) => void;
    setCurrentChildOffset?: (offset: number) => void;
}
const ListComponent: React.FC<I> = props => {
    const ref = useRef<HTMLDivElement>(null);
    const [columnNum, setColumnNum] = useState(1);

    const computeColumnNum = useCallback(() => {
        if (ref.current === null) {
            return;
        }
        const { offsetWidth } = ref.current;
        if (offsetWidth === 0) return;
        const breakGap = props.breakpoints.find(p => p.uptoWidth > offsetWidth);
        if (breakGap?.colum !== columnNum) {
            setColumnNum(breakGap?.colum ?? 1);
            props.onBreak && props.onBreak(breakGap?.colum ?? 1);
        }
    }, [props, columnNum]);

    useEffect(() => {
        if (ref.current) {
            computeColumnNum();
            window.addEventListener('resize', computeColumnNum);
        }
        return () => {
            window.removeEventListener('resize', computeColumnNum);
        };
    }, [computeColumnNum]);

    const [pageIndex, setPageIndex] = useState(1);
    const pageSize = props.row * columnNum;
    const childArray = React.Children.toArray(props.children);
    const showChildrenArray = childArray.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
    useEffect(() => {
        setPageIndex(1);
    }, [pageSize]);

    useEffect(() => {
        props.setCurrentChildNum && props.setCurrentChildNum(showChildrenArray.length);
        props.setCurrentChildOffset && props.setCurrentChildOffset((pageIndex - 1) * pageSize);
    }, [pageIndex, pageSize, props, showChildrenArray.length]);

    return (
        <>
            <Wrap
                className='list-component'
                column={columnNum}
                rowGap={props.rowGap}
                columnGap={props?.columnGap || '40px'}
                ref={ref}
            >
                {showChildrenArray}
            </Wrap>
            {props.withPagination && (
                <PaginationWrap>
                    <Pagination
                        style={{ position: 'relative', top: props.paginationTop ?? 0 }}
                        size='small'
                        total={childArray.length}
                        pageSize={pageSize}
                        onChange={setPageIndex}
                        current={pageIndex}
                        showSizeChanger={false}
                    />
                </PaginationWrap>
            )}
        </>
    );
};

export default ListComponent;

const Wrap = styled.div<{ column: number; rowGap: string; columnGap: string }>`
    display: grid;
    grid-template-columns: repeat(${props => props.column}, 1fr);
    row-gap: ${props => props.rowGap};
    column-gap: ${props => props.columnGap};
`;

const PaginationWrap = styled.div`
    text-align: right;
`;
