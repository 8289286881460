import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import '../style/components.less';

const Primary: React.FC<{
    htmlType?: 'button' | 'submit' | 'reset';
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = props => {
    return (
        <button className='primary-button' onClick={props.onClick} type={props.htmlType}>
            {props.children}
        </button>
    );
};

const Secondary: React.FC<{
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = props => {
    return (
        <button className='secondary-button' onClick={props.onClick}>
            {props.children}
        </button>
    );
};

const Small: React.FC<{
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = props => {
    return (
        <button className='small-button' onClick={props.onClick}>
            {props.children}
        </button>
    );
};

const Round: React.FC<{
    icon: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = props => {
    return <Button type='default' shape='circle' size='large' onClick={props.onClick} icon={props.icon} />;
};

const Btn = { Primary, Secondary, Small, Round };

export default Btn;
