import { Col, Divider, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { EntityType } from '../../constant';
import Btn from './myButton.component';

const AppSummaryCard: React.FC<{
    id: number;
    image: JSX.Element;
    title: string;
    description: string;
    withBottomDivider?: boolean;
}> = ({ id, image, title, description, withBottomDivider }) => {
    const history = useHistory();
    return (
        <div className='app-item' onClick={() => history.push(`/${EntityType.App}/${id}`)}>
            <div className='logo small-logo'>{image}</div>
            <div className='main'>
                <Row wrap={false} className='content'>
                    <Col flex={1} className='detail'>
                        <h3>{title}</h3>
                        <div className='description'>{description}</div>
                    </Col>
                    <Col>
                        <Btn.Small onClick={() => history.push(`/${EntityType.App}/${id}`)}>详情</Btn.Small>
                        {/* <Link to={`/${EntityType.App}/${id}`}>
                            <Btn.Small>详情</Btn.Small>
                        </Link> */}
                    </Col>
                </Row>
                {withBottomDivider && (
                    <Row align='bottom'>
                        <Divider className='divider' />
                    </Row>
                )}
            </div>
        </div>
    );
};

export default AppSummaryCard;
