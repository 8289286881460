import { EntityType } from '../constant';
import instance from './axios';

const getBanner = async () => {
    const res = await instance.get<Res.Wrap<B.TopBanner[]>>('/api/top-banners');
    return res.data.data;
};

// 精选
const getHighlightApps = async () => {
    const res = await instance.get<Res.Wrap<B.Highlights>>('/api/highlights/latest');
    const highlight = res.data.data;
    const apps = await Promise.all((highlight?.appIds ?? []).map(getAppSummaryById));
    const models = highlight?.models;
    return { apps, models };
};

// 点击精选里面的全部，进入全部app
const getAllAppSummaries = async (start: number, max: number) => {
    const summaries = (
        await instance.get<Res.Wrap<Res.Apps[]>>(`/api/${EntityType.App}`, {
            params: {
                start,
                max,
            },
        })
    ).data.data;
    return summaries;
};

// 点击全部app中的某一个，进入app详情页
const getAppSummaryById = async (appId: Res.AppId) => {
    const res = await instance.get<Res.Wrap<B.AppSummary>>(`/api/${EntityType.App}/${appId}`);
    return res.data.data;
};

// 点击全部app中的某一个，进入app详情页
const getAppDetailById = async (appId: Res.AppId) => {
    const res = await instance.get<Res.Wrap<B.AppDetail>>(`/api/${EntityType.App}/${appId}`, {
        params: { detail: true },
    });
    return res.data.data;
};

export const getModelById = async (appId: Res.AppId) => {
    const res = await instance.get<B.Model>(`/api/model/${appId}`);
    return res.data;
};

// 获得全部解决方案，with 相关app summary
export interface LocalSolution extends Omit<B.Solution, 'highlights'> {
    highlights: B.AppSummary[];
}
const getSolutions = async (): Promise<LocalSolution[]> => {
    const solutions = (await instance.get<Res.Wrap<B.Solution[]>>('/api/solutions')).data.data;
    const createOneLocalSolution = async (solution: B.Solution): Promise<LocalSolution> => {
        const appSummaries = await Promise.all(solution.highlights.map(getAppSummaryById));
        return { ...solution, highlights: appSummaries };
    };
    const localSolutions = await Promise.all(solutions.map(createOneLocalSolution));
    return localSolutions;
};

// 全部应用的解决方案tab页

const getAppBySolutionId = async (solutionId: Res.SolutionId) => {
    const res = await instance.get<Res.Wrap<B.AppSummary[]>>(`/api/${EntityType.App}/query`, {
        params: {
            solutionId,
        },
    });

    const apps = res.data.data;
    return apps;
};

export const getModelCategories = async () => {
    const res = await instance.get<B.ModelCategory[]>('/api/model-category');
    const modelCategories = res.data;
    return modelCategories;
};

export const getRecommendation = async (type: EntityType, id: number) => {
    const res = await instance.get<(B.AppSummary | B.Model)[]>('/api/recommendation', {
        params: {
            type,
            id,
        },
    });
    const modelCategories = res.data;
    return modelCategories;
};

export const getModelByCategoryId = async (categoryId?: number) => {
    const res = await instance.get<B.Model[]>('/api/model', {
        params: {
            categoryId,
        },
    });
    const models = res.data;
    return models;
};

export const sendContactInfo = async (contactInfo: B.ContactInfo) => instance.post('/api/contacts', contactInfo);

export const getSearchAppModel = async (searchWord: string): Promise<(B.Model | B.AppSummary)[]> => {
    const res = await instance.get<(B.AppSummary | B.Model)[]>(`/api/search/${searchWord}`);
    const models = res.data;
    return models;
};

export default {
    getBanner,
    getHighlightApps: getHighlightApps,
    getAllAppSummaries,
    getAppDetailById: getAppDetailById,
    getAppBySolutionId,
    getSolutions,
    sendContactInfo,
};
