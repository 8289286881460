export const MOBILE_WIDTH = 820;

export const demoStatusMap = {
    SUCCESSED: '成功',
    FAILED: '失败',
};

export const CAROUSEL = {
    width: 720,
    height: 200,
};

export const APP_COVER = {
    width: 250,
    height: 150,
};

export const APP_ROW_COVER = {
    width: 150,
    height: 150,
};

export const PARTNER = {
    width: 250,
    height: 150,
};

export const APP_DETAIL = {
    width: MOBILE_WIDTH,
    height: 350,
};

export enum EntityType {
    App = 'app',
    Model = 'model',
}

export const SWIPER_BREAKPOINT = [
    { min: 0, max: MOBILE_WIDTH - 100, column: 1 },
    { min: MOBILE_WIDTH - 10, max: 1200, column: 2 },
    { min: 1200, max: 1536, column: 3 },
    { min: 1536, max: 6000, column: 4 },
];

export const LIST_BREAKPOINT = [
    { uptoWidth: MOBILE_WIDTH - 100, colum: 1 },
    { uptoWidth: 1200, colum: 2 },
    { uptoWidth: 1586, colum: 3 },
    { uptoWidth: 15360, colum: 4 },
];

export const LIST_LAYOUT = { xs: 1, md: 2, lg: 3, xl: 4 };

export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
