import { useMatomo } from '@datapunt/matomo-tracker-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { EntityType, SWIPER_BREAKPOINT } from '../../constant';
import ModelSummaryComponent from '../components/modelSummary.component';
import SwiperComp from '../components/swiper';
import { getImage } from '../utils/placeholder';

interface I {
    title: string;
    items: B.Model[];
}
const HighlightModelContainer: React.FC<I> = props => {
    const { title, items } = props;
    const { trackPageView } = useMatomo();

    // Track page view
    React.useEffect(() => {
        trackPageView({ href: window.location.href });
    }, []);

    return (
        <div className='highlight'>
            <div className='header'>
                <span>{title}</span>
                <span className='tab-button'>
                    <Link to={{ pathname: EntityType.Model, state: { chosenTabId: 'highlight' } }}>查看全部</Link>
                </span>
            </div>
            <SwiperComp margin={16} flexInterval={SWIPER_BREAKPOINT}>
                {items.map(model => (
                    <Link key={model.id} to={`${EntityType.Model}/${model.id}`}>
                        <ModelSummaryComponent
                            summary={model}
                            image={getImage(model.image ?? model.categories[0].image).jsx}
                        />
                    </Link>
                ))}
            </SwiperComp>
        </div>
    );
};

export default HighlightModelContainer;
