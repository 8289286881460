import React, { useState, useEffect } from 'react';
import SearchResultContainer from '../containers/searchResult.container';
import Header from './header.page';
import Navigator from '../components/navigator.component';
import '../style/search-result.less';

const SearchResultPage: React.FC = props => {
    return (
        <>
            <Header leftComponent={<Navigator info={[{ name: '搜索结果' }]} />} />
            <div className='search-result-page page-padding'>
                <SearchResultContainer />
            </div>
        </>
    );
};

export default SearchResultPage;
