import React from 'react';

function Image(str: string, width: number, height: number, styles: React.CSSProperties = {}) {
    return (
        <img
            // src={`https://via.placeholder.com/${width}x${height}.webp?text=${str.split(' ').join('+')}`}
            // src={`https://loremflickr.com/${width}/${height}`}
            // src={`https://www.fillmurray.com/${width}/${height}`}
            // src={`http://placeimg.com/${width}/${height}/any`}
            src={`https://picsum.photos/${width}/${height}`}
            key={str}
            alt={str}
            style={{ maxWidth: '100%', ...styles }}
        />
    );
}

export const getImage = (name?: string) => ({
    jsx: name ? <img src={`/api/files/${name}`} alt={name} key={name} /> : <div />,
    url: `/api/files/${name}`,
});

export default { image: Image };
