import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import HomeCarousel from '../containers/carousel.container';
import HighlightApp from '../containers/appHighlight.container';
import HighlightModelContainer from '../containers/highlightModel.container';
import SolutionSummary from '../containers/solutionSummary.container';
import '../style/home.less';
import Header from './header.page';
import { trackEvent, useTrackVisit } from '../../api/seo';
import { getPathUrlByEvent } from '../../utils/common';

export const useHighlight = (): { apps: B.AppSummary[]; models: B.Model[] } => {
    const [apps, setApps] = useState<B.AppSummary[]>([]);
    const [models, setModels] = useState<B.Model[]>([]);
    useEffect(() => {
        const fetchHighlight = async () => {
            const { apps, models } = await api.getHighlightApps();
            if (apps) setApps(apps);
            if (models) setModels(models);
        };
        fetchHighlight();
    }, []);
    return { apps, models };
};

const Home = () => {
    const { apps, models } = useHighlight();

    useTrackVisit('首页');

    return (
        <>
            <Header />
            <div className='page-padding'>
                <div className='home'>
                    <div onClick={e => trackEvent('推荐', '首页横幅', getPathUrlByEvent(e))}>
                        <HomeCarousel />
                    </div>

                    <div onClick={e => trackEvent('推荐', 'App精选', getPathUrlByEvent(e))}>
                        <HighlightApp title='应用精选' items={apps} />
                    </div>
                    <div onClick={e => trackEvent('推荐', '模型精选', getPathUrlByEvent(e))}>
                        <HighlightModelContainer title='模型精选' items={models} />
                    </div>

                    <div onClick={e => trackEvent('推荐', '首页列表', getPathUrlByEvent(e))}>
                        <SolutionSummary />
                    </div>

                    {/* <Partners /> */}
                </div>
            </div>
        </>
    );
};

export default Home;
