import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useState, useEffect } from 'react';

interface I {
    paddingTop?: number;
    paddingBottom?: number;
    className?: string;
    fallback?: JSX.Element;
    showFallback?: boolean;
    isLoading?: boolean;
}
const DisplayPadComponent: React.FC<I> = props => {
    const { isLoading } = props;
    const showFallback = !props.children || props.showFallback;
    const antIcon = <LoadingOutlined className='fallback' style={{ fontSize: 64 }} spin />;

    return (
        <div
            className={`display-pad ${props.className ?? ''}`}
            style={{
                paddingTop: props.paddingTop ?? 32,
                paddingBottom: props.paddingBottom ?? 32,
                minHeight: showFallback ? 661 : 0,
            }}
        >
            {props.children}
            {props.isLoading && <Spin indicator={antIcon} />}
            {!isLoading && showFallback && props.fallback}
        </div>
    );
};

export default DisplayPadComponent;
