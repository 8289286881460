import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './router';
import store from './store';

import 'normalize.css';
import 'antd/dist/antd.less';
import './react/style/index.less';
import './react/style/common.less';
import ProxyList from './react/components/proxyList.component';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { BrowserRouter } from 'react-router-dom';

const instance = createInstance({
    urlBase: window.location.href,
    siteId: 1,
    trackerUrl: 'http://localhost:8080',
});

ReactDOM.render(
    <MatomoProvider value={instance}>
        <Provider store={store}>
            <BrowserRouter>
                {ProxyList && <ProxyList />}
                <App />
            </BrowserRouter>
        </Provider>
    </MatomoProvider>,
    document.getElementById('app'),
);
