import React from 'react';
import { Typography, Row, Image } from 'antd';
import ReactMarkdown from 'react-markdown';
import { SWIPER_BREAKPOINT } from '../../constant';
import { getImage } from '../utils/placeholder';
import DisplayPadComponent from './displayPad.component';
import SwiperComp from './swiper';

interface I {
    title: string;
    content: B.LongIntroPart[];
}
const DetailEqualWidthAndSwipeComponent: React.FC<I> = props => {
    const { title, content } = props;

    return (
        <>
            <Typography.Title className='title' level={4}>
                {title}
            </Typography.Title>
            <SwiperComp margin={16} flexInterval={SWIPER_BREAKPOINT} noBlankTail>
                {content.map(l => (
                    <DisplayPadComponent key={l.title} paddingTop={32} paddingBottom={32} className='card'>
                        {l.imageName && (
                            <Row className='symbol'>
                                {l.imageName && (
                                    <img width='auto' height={64} src={getImage(l.imageName).url} alt='logo' />
                                )}
                            </Row>
                        )}
                        <Row>
                            <Typography.Title className='sub-title' level={5}>
                                {l.title}
                            </Typography.Title>
                        </Row>
                        <Row>
                            <ReactMarkdown className='content'>{l.content}</ReactMarkdown>
                        </Row>
                    </DisplayPadComponent>
                ))}
            </SwiperComp>
        </>
    );
};

export default DetailEqualWidthAndSwipeComponent;
