import { Col, Divider, Row, Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import api, { LocalSolution } from 'src/api/api';
import AppSummaryCard from '../components/appSummaryCard.component';
import { getImage } from 'src/react/utils/placeholder';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { computeDivider } from '../utils';
import { EntityType, LIST_BREAKPOINT } from '../../constant';
import ModelCardComponent from '../components/modelCard.component';
import DisplayPadComponent from '../components/displayPad.component';
import ListComponent from '../components/list.component';
import { useIsMobile } from '../hooks/layout.hook';

const SolutionSummary = () => {
    const [solutions, setSolutions] = useState<LocalSolution[]>([]);
    useEffect(() => {
        api.getSolutions().then(setSolutions);
    }, []);

    const [chosenSolutionId, setChosenSolutionId] = useState('');
    useEffect(() => {
        if (solutions[0]) setChosenSolutionId(`${solutions[0].id}`);
    }, [solutions]);

    const [appColumn, setAppColumn] = useState(1);
    const isMobile = useIsMobile();
    return (
        <ChosenTabStyle className='solution-summary'>
            <Tabs defaultActiveKey={solutions[0]?.name || ''} onChange={setChosenSolutionId}>
                {solutions.map(solution => (
                    <Tabs.TabPane tab={solution.name} key={solution.id}>
                        <DisplayPadComponent paddingTop={0} paddingBottom={32}>
                            <Row align='middle' justify='space-between'>
                                <Col>
                                    <Typography.Title style={{ margin: '24px 0' }} level={4}>
                                        APP应用
                                    </Typography.Title>
                                </Col>
                                <Col>
                                    <span>
                                        <Link
                                            to={{ pathname: EntityType.App, state: { chosenTabId: chosenSolutionId } }}
                                        >
                                            查看全部
                                        </Link>
                                    </span>
                                </Col>
                            </Row>
                            <ListComponent
                                row={isMobile ? solution.highlights.length : 2}
                                rowGap='10px'
                                breakpoints={LIST_BREAKPOINT}
                                onBreak={setAppColumn}
                            >
                                {solution.highlights.map((app, index) => (
                                    <AppSummaryCard
                                        key={app.id}
                                        id={app.id}
                                        image={getImage(app.logo).jsx}
                                        title={app.name}
                                        description={app.shortIntroduction}
                                        withBottomDivider={
                                            computeDivider(
                                                appColumn,
                                                index,
                                                isMobile
                                                    ? solution.highlights.length
                                                    : Math.min(solution.highlights.length, appColumn * 2),
                                            ).lastLine
                                        }
                                    />
                                ))}
                            </ListComponent>

                            {solution.models?.length > 0 && (
                                <>
                                    <Divider className='big-divider' />
                                    <Row align='middle' justify='space-between'>
                                        <Col>
                                            <Typography.Title style={{ margin: '24px 0' }} level={4}>
                                                模型服务
                                            </Typography.Title>
                                        </Col>
                                        <Col>
                                            <span>
                                                <Link
                                                    to={{
                                                        pathname: EntityType.Model,
                                                        state: { chosenTabId: 'all' },
                                                    }}
                                                >
                                                    查看全部
                                                </Link>
                                            </span>
                                        </Col>
                                    </Row>

                                    <ListComponent
                                        row={isMobile ? solution.models.length : 2}
                                        rowGap='1em'
                                        breakpoints={LIST_BREAKPOINT}
                                        onBreak={setAppColumn}
                                    >
                                        {solution.models.map((m, i) => (
                                            <ModelCardComponent
                                                key={m.id}
                                                id={m.id}
                                                name={m.name}
                                                type={EntityType.Model}
                                                description={m.shortIntroduction}
                                                withDivider={
                                                    computeDivider(
                                                        appColumn,
                                                        i,
                                                        isMobile
                                                            ? solution.models.length
                                                            : Math.min(solution.models.length, appColumn * 2),
                                                    ).lastLine
                                                }
                                            />
                                        ))}
                                    </ListComponent>
                                </>
                            )}
                        </DisplayPadComponent>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </ChosenTabStyle>
    );
};

export default SolutionSummary;

export const SolutionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
    padding-bottom: 1rem;
`;

export const ChosenTabStyle = styled.div`
    .ant-tabs-tab-btn {
        height: 24px;
        font-size: 20px;
        color: #333333;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
        font-size: 24px;
        color: #333333;
        margin-bottom: 5px;
    }
`;
