import React from 'react';
import DetailAlwaysTwoColumnAndSwipeComponent from './DetailAlwaysTwoColumnAndSwipe.component';
import DetailEqualWidthAndSwipeComponent from './DetailEqualWidthAndSwipe.component';
import DetailEqualWidthAndTileComponent from './DetailEqualWidthAndTile.component';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum LongIntroUnitType {
    equalWidthAndSwipe = 'equalWidthAndSwipe',
    equalWidthAndTile = 'equalWidthAndTile',
    alwaysTwoColumnAndSwipe = 'alwaysTwoColumnAndSwipe',
}

interface I {
    longIntroUnit: B.LongIntroUnit;
}
const DetailIntroductionV2Component: React.FC<I> = props => {
    const { longIntroUnit: unit } = props;

    switch (unit.type) {
        case LongIntroUnitType.alwaysTwoColumnAndSwipe:
            return <DetailAlwaysTwoColumnAndSwipeComponent title={unit.title} content={unit.content} />;

        case LongIntroUnitType.equalWidthAndSwipe:
            return <DetailEqualWidthAndSwipeComponent title={unit.title} content={unit.content} />;

        case LongIntroUnitType.equalWidthAndTile:
            return <DetailEqualWidthAndTileComponent title={unit.title} content={unit.content} />;

        default:
            return <></>;
    }
};

export default DetailIntroductionV2Component;
