import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '../style/swiper.less';
interface FlexInterval {
    max: number;
    min: number;
    column: number;
}
interface SwiperProps {
    flexInterval: FlexInterval[];
    margin: number;
    noBlankTail?: boolean;
}
const SwiperComp: React.FC<SwiperProps> = memo(props => {
    const { flexInterval, margin } = props;
    // 默认三列
    const [column, setColumn] = useState<number>(2);
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const [appInterval, setAppInterval] = useState<number>(0);
    const [transVal, setTransVal] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const swiperRef = useRef(null);

    const childrenArray = React.Children.toArray(props.children);

    const childrenRowWidth = useMemo(() => {
        return (
            ((windowWidth - (column - 1) * margin) / column) * childrenArray.length +
            margin * (childrenArray.length - 1)
        );
    }, [childrenArray.length, column, margin, windowWidth]);

    const computedTransVal = useCallback(() => {
        const pageNum = Math.min(page, Math.ceil(childrenArray.length / column));
        if (pageNum === 0) return setTransVal(0);
    }, [column, page, childrenArray.length]);

    const initData = useCallback(() => {
        const current: any = swiperRef.current;
        const { offsetWidth } = current;
        // 不存在使用默认值
        for (const val of flexInterval) {
            if (offsetWidth >= val.min && offsetWidth <= val.max) {
                if (props.noBlankTail) setColumn(Math.min(val.column, childrenArray.length));
                else setColumn(val.column);
            }
        }
        setWindowWidth(offsetWidth);
        // 大于两列的时候容器的间距计算
        setAppInterval((offsetWidth - (column - 1) * margin) / column);
        computedTransVal();
    }, [childrenArray.length, column, computedTransVal, flexInterval, margin, props.noBlankTail]);

    useEffect(() => {
        if (swiperRef.current) {
            initData();
            window.addEventListener('resize', initData);
        }
        return () => {
            window.removeEventListener('resize', initData);
        };
    }, [initData]);

    const instanceContainer = useCallback(() => {
        // 容器整体宽度
        const swiperContainerWidth = margin * (childrenArray.length - 1) + appInterval * childrenArray.length;
        return (
            <div
                className='app-store-swiper-container'
                style={{
                    width: `${swiperContainerWidth}px`,
                    height: '100%',
                    transform: `translate(${-transVal}px, 0px)`,
                }}
                ref={swiperRef}
            >
                {childrenArray.map((item, index) => {
                    if (index === childrenArray.length - 1) {
                        return (
                            <div key={index} style={{ width: `${appInterval}px`, float: 'left' }}>
                                {item}
                            </div>
                        );
                    }
                    return (
                        <div
                            key={index}
                            style={{
                                width: `${appInterval}px`,
                                marginRight: `${margin}px`,
                                float: 'left',
                            }}
                        >
                            {item}
                        </div>
                    );
                })}
            </div>
        );
    }, [appInterval, childrenArray, margin, transVal]);

    return (
        <div className='app-store-swiper' ref={swiperRef}>
            {transVal > 0 && (
                <span
                    className='left-btn position-left'
                    onClick={() => {
                        const pageNum = page - 1;
                        if (transVal - (windowWidth + appInterval) < 0) {
                            setTransVal(0);
                        } else {
                            setTransVal(transVal - (windowWidth + margin));
                        }
                        setPage(pageNum);
                    }}
                />
            )}
            <div className='app-store-swiper-content'>{instanceContainer()}</div>
            {transVal < childrenRowWidth - windowWidth && (
                <span
                    className='right-btn position-right'
                    onClick={() => {
                        const pageNum = page + 1;
                        // 当前移动的像素小于最后一页
                        if ((windowWidth + appInterval) * pageNum > childrenRowWidth - windowWidth) {
                            setTransVal(childrenRowWidth - windowWidth);
                        } else {
                            setTransVal((windowWidth + margin) * pageNum);
                        }
                        setPage(pageNum);
                    }}
                />
            )}
        </div>
    );
});

export default SwiperComp;
