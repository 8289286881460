import React from 'react';
import styled from 'styled-components';

interface I {
    summary: B.Model;
    image: JSX.Element;
}
const ModelSummaryComponent: React.FC<I> = props => {
    const { summary, image } = props;
    return (
        <CardItem className='highlight-card model'>
            <div className='upper'>
                <div className='image'>{image}</div>
                <div className='model-info'>
                    <h3 className='title'>{summary.name}</h3>
                    <div className='version'>版本：{summary.version}</div>
                </div>
                <button className='secondary-button'>详情</button>
            </div>
            <div className='lower'>
                <div className='description'>
                    <p style={{ color: '#23252d' }}>模型描述：</p>
                    {summary.shortIntroduction}
                </div>
            </div>
        </CardItem>
    );
};

export default ModelSummaryComponent;

const CardItem = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(176, 184, 195, 0.55);
    padding: 8px 4px;
`;
