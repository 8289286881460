import { AutoComplete, Input, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getSearchAppModel } from '../../api/api';
import _ from 'lodash';
import '../style/components/search.less';
import searchIcon from '../style/image/serch-icon.svg';
import { trackEvent } from '../../api/seo';
import closeBtn from '../../resource/image/close-btn.svg';
import { useIsMobile } from '../hooks/layout.hook';

type Option = { value: (JSX.Element | string)[]; text: string };

interface Props {
    className?: string;
    onHidden?: () => void;
}

const SearchComponent: React.FC<Props> = props => {
    const [options, setOptions] = useState<Option[]>([]);
    const [value, setValue] = useState('');

    const history = useHistory();

    const onSearchInput = async (searchText: string) => {
        if (!searchText) {
            setOptions([]);
            return;
        }

        const result = await getSearchAppModel(searchText);
        const names = _.uniqBy(result, 'name').map(r => {
            // 这一切，只是为了给匹配的search word加span，上色
            const words = r.name.split(searchText);
            const out: (string | JSX.Element)[] = [];
            words.forEach((w, i) => {
                if (i < words.length - 1) {
                    out.push(w);
                    out.push(<span>{searchText}</span>);
                }
            });
            out.push(words[words.length - 1]);
            return { value: out, text: r.name };
        });
        // 最多前 10 个结果
        setOptions(names.slice(0, 10));
    };

    const onSearchExecute = (option: Option) => {
        if (option) {
            setValue('');
            setOptions([]);
            history.push(`/search/${option.text}`);
        }
    };

    const onSearchWithOption = (option: Option) => {
        trackEvent('搜索', '点击搜索推荐词', option.text);
        onSearchExecute(option);
    };

    const onSearchWithBtn = (option: Option) => {
        trackEvent('搜索', '点击搜索按钮', option.text);
        onSearchExecute(option);
    };

    const ref = useRef<Input>(null);
    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
        }
    }, []);

    const isMobile = useIsMobile();
    const prefix = isMobile ? (
        <img src={searchIcon} alt='搜索文本' onClick={() => onSearchWithBtn({ text: value, value: [value] })} />
    ) : undefined;

    const suffix = isMobile ? (
        props.onHidden && <img className='close-btn' src={closeBtn} alt='关闭搜索栏' onClick={props.onHidden} />
    ) : (
        <img
            src={searchIcon}
            style={{ height: 28 }}
            alt='搜索文本'
            onClick={() => onSearchWithBtn({ text: value, value: [value] })}
        />
    );

    return (
        <AutoComplete
            className={`search ${props.className ?? ''}`}
            dropdownClassName='search-select'
            options={options as any}
            onSearch={onSearchInput}
            value={value}
            onChange={setValue}
            onSelect={(el, option: any) => onSearchWithOption(option)}
        >
            <Input ref={ref} allowClear size='large' prefix={prefix} suffix={suffix} />
        </AutoComplete>
    );
};

export default SearchComponent;
