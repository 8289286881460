import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'src/api/api';
import Navigator from '../components/navigator.component';
import { getImage } from 'src/react/utils/placeholder';
import '../style/appDetail.less';
import { EntityType } from '../../constant';
import ModelDetail from '../containers/modelDetail.container';
import Header from './header.page';
import { useTrackVisit } from '../../api/seo';

const SingleApp = () => {
    const { appId } = useParams<{ appId: string }>();

    const [app, setApp] = useState<B.AppDetail>();
    useEffect(() => {
        api.getAppDetailById(Number(appId)).then(setApp);
    }, [appId]);

    useTrackVisit(app ? `${app?.name}` : '', [app]);

    return (
        <>
            <Header
                leftComponent={
                    <Navigator info={[{ name: '全部', link: `/${EntityType.App}` }, { name: '应用详情' }]} />
                }
                hiddenSearch
            />
            <div className='page-padding'>
                {app && (
                    <ModelDetail
                        id={app.id}
                        type={EntityType.App}
                        logo={getImage(app.logo).jsx}
                        imageUrls={app.covers?.map(name => getImage(name).url) ?? []}
                        title={app.name}
                        categories={app.catelogies}
                        version={app.version}
                        shortIntroduction={app.detailShortIntroduction}
                        longIntroduction={[app.spotIntroduction, app.sceneIntroduction, app.caseIntroduction]}
                        longIntroductionV2={app.detailIntroductionV2}
                    />
                )}
            </div>
        </>
    );
};

export default SingleApp;
