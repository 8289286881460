import cloudbase from '@cloudbase/js-sdk';
import useSWR from 'swr';

const ENV_ID = 'asr-tts-5g19m52fe805f90e';

const app = cloudbase.init({
    env: ENV_ID,
});

app.auth({ persistence: 'local' }).anonymousAuthProvider().signIn();

const db = app.database();

enum EntityType {
    model = 'model',
    app = 'app',
}

export const useModelTrailUrl = (id: number) => {
    const fetcher = (type: EntityType, id: number) =>
        db
            .collection('model')
            .where({
                sql_id: id.toString(),
            })
            .get()
            .then(res => res.data);

    const { data } = useSWR([EntityType.model, id], fetcher);
    return { data };
};
