import React from 'react';
import Navigator from '../components/navigator.component';
import ModelSummaryContainer from '../containers/modelSummary.container';
import Header from './header.page';

const ModelsPage: React.FC = () => {
    return (
        <>
            <Header leftComponent={<Navigator info={[{ name: '全部' }]} />} />
            <div className='page-padding'>
                <ModelSummaryContainer />
            </div>
        </>
    );
};

export default ModelsPage;
