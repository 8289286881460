import React from 'react';
import { Typography, Row } from 'antd';
import ReactMarkdown from 'react-markdown';
import { getImage } from '../utils/placeholder';
import DisplayPadComponent from './displayPad.component';
import SwiperComp from './swiper';

interface I {
    title: string;
    content: B.LongIntroPart[];
}
const DetailAlwaysTwoColumnAndSwipeComponent: React.FC<I> = props => {
    const { title, content } = props;

    return (
        <>
            <Typography.Title className='title' level={4}>
                {title}
            </Typography.Title>

            <SwiperComp
                margin={16}
                flexInterval={[
                    { min: 0, max: 1164, column: 1 },
                    { min: 1153, max: 1536, column: 2 },
                    { min: 1536, max: 6000, column: 2 },
                ]}
            >
                {content.map(l => (
                    <DisplayPadComponent key={l.title} paddingTop={32} paddingBottom={32}>
                        <Row className='symbol'>
                            {l.imageName && <img alt='logo' height={64} width='auto' src={getImage(l.imageName).url} />}
                        </Row>
                        <Row>
                            <Typography.Title className='sub-title' level={5}>
                                {l.title}
                            </Typography.Title>
                        </Row>
                        <Row>
                            <ReactMarkdown className='content'>{l.content}</ReactMarkdown>
                        </Row>
                    </DisplayPadComponent>
                ))}
            </SwiperComp>
        </>
    );
};

export default DetailAlwaysTwoColumnAndSwipeComponent;
