import { RightOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import '../style/components.less';

type NavigatorMata = {
    name: string;
    link?: string;
}[];

const currentPageStyle = {
    opacity: 0.4,
    fontSize: 14,
    lineHeight: 1.14,
    color: '#333333',
};

const otherPageStyle = {
    fontSize: 14,
    lineHeight: 1.14,
    color: '#333333',
};

const Navigator: React.FC<{ info: NavigatorMata }> = props => {
    return (
        <div className='breadcrumb'>
            <span className='decoration'></span>
            <Breadcrumb className='breadcrumb' separator={<RightOutlined />}>
                <Breadcrumb.Item key='/'>
                    <Link to='/'>
                        <span style={otherPageStyle}>App Store 首页</span>
                    </Link>
                </Breadcrumb.Item>
                {props.info.map(({ name, link }, index) => (
                    <Breadcrumb.Item key={name}>
                        {link ? (
                            <Link to={link}>
                                <span style={index === props.info.length - 1 ? currentPageStyle : otherPageStyle}>
                                    {name}
                                </span>
                            </Link>
                        ) : (
                            <span style={index === props.info.length - 1 ? currentPageStyle : otherPageStyle}>
                                {name}
                            </span>
                        )}
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </div>
    );
};

export default Navigator;
