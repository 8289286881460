import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../style/header.less';
import { inIframe } from '../utils';
import logo from '../../resource/image/logo.png';
import SearchMobileComponent from '../components/searchMobile.component';

interface I {
    leftComponent?: JSX.Element;
    hiddenSearch?: boolean;
}
const Header: React.FC<I> = props => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    const homeCompnent = <div className='home-page-component'>欢迎来到AIOS Store</div>;
    const AIOSHeader = (
        <header className='aios-header'>
            {isHomePage ? homeCompnent : props.leftComponent}
            {location.pathname !== '/contact' && !props.hiddenSearch && <SearchMobileComponent />}
        </header>
    );

    const WebHeader = (
        <header>
            <div className='web-header'>
                <div className='banner'>
                    <Link to='/'>
                        <img src={logo} alt='logo' className='logo' />
                        4Paradigm Sage App Store
                        <br />
                        企业级AI应用市场
                    </Link>
                    <SearchMobileComponent />
                </div>
            </div>
            {!isHomePage && props.leftComponent}
        </header>
    );

    return inIframe() ? AIOSHeader : WebHeader;
};

export default Header;
