import { useEffect, useState } from 'react';
import { MOBILE_WIDTH } from '../../constant';

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (window.innerWidth < MOBILE_WIDTH) setIsMobile(true);
        else setIsMobile(false);
    }, []);
    return isMobile;
}
