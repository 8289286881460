import React, { useState, useEffect } from 'react';
import CarouselWithImage from 'src/react/components/carousel.component';
import { getImage } from 'src/react/utils/placeholder';
import api from 'src/api/api';

const HomeCarousel = () => {
    const [banners, setBannsers] = useState<B.TopBanner[]>([]);
    useEffect(() => {
        api.getBanner().then(setBannsers);
    }, []);

    const data = banners.map(banner => ({
        description: (
            <div className='text'>
                {banner.title.trim() && <h2>{banner.title}</h2>}
                {banner.content.trim() && <p>{banner.content}</p>}
            </div>
        ),
        imageUrl: getImage(banner.imageName).url,
        url: banner.url,
    }));

    return <CarouselWithImage data={data} />;
};

export default HomeCarousel;
